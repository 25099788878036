import React from 'react';
import './banner.css';
import headShotPicture from '../../images/headshot_1_smaller_highres.jpg'

function Banner () {
    return(
        <div id="heading">
                <div id="hr-holder-pic">
                    <div id="headshot-pic-container">
                        <img src={headShotPicture} id="headshot-pic" alt="handsome man"></img>
                    </div>
                </div>
    
                <div id="heading-text">
                    <h1>Jakub Bala</h1>
                </div>
        </div>
    )
}

export default Banner;