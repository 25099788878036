import React from 'react';
import GameJamBox from './GameJamBox';

import ThatWasntWheatImg from '../../../images/jams/thatwasntwheat.png';
import SkyPiratesImg from '../../../images/jams/pirates.png';
import MortalCompassImg from '../../../images/jams/mortalcompass.png';
import EventHorizonImg from '../../../images/jams/eventhorizon.png';

function GameJams({isVisible}){
    return(
        <div>
            <p>Since starting them in 2021, I have participated in 8 game jam events. These are where you are given between 24-72 hours
                to create a game from scratch based around a theme that is revealed when the time starts. The below 4 games were our most succesful
                entries, made by me and my friends, Sam and Julius.
            </p>
            <p>Hover over them to learn more, or click to be taken to where you can play the game.</p>
            <p className="aside-text">(Click on mobile for info, click to take to game is disabled for mobile)</p>
            <div id="game-jams-container" className={isVisible ? 'show' : 'hidden'}>
                
                <GameJamBox name="Event Horizon" link="https://ldjam.com/events/ludum-dare/54/event-horizon"
                image={EventHorizonImg} altText="'Event Horizon' screenshot"
                theme={'"Space is Limited"'} 
                description="In this game you are orbiting a black hole, while trying to avoid asteroids.
                We made this game without using an engine, only C# and MonoGame, which inspired us to start 
                making our own engine!"
                madeForText="Ludum Dare 54"
                timeText="72 Hours"
                resultText="Placed 730th / ~2200"
                />

                <GameJamBox name="That Wasn't Wheat" link="https://sammot.itch.io/that-wasnt-wheat"
                image={ThatWasntWheatImg} altText="'That Wasnt Wheat' screenshot"
                theme={'"Harvest"'} 
                description="In this game your job is to harvest all the wheat in time with your 
                combine harvester, but there are obstacles in your way that you must make sure
                to avoid"
                madeForText="Ludum Dare 52"
                timeText="72 Hours"
                resultText="Placed 300th / ~2000"
                />

                <GameJamBox name="The Despicablest of Sky Pirates" link="https://contraband.software/pirate-face-off/game"
                image={SkyPiratesImg} altText="'The Despicablest of Sky Pirates' screenshot"
                theme={'"Villains"'} 
                description="A 2 player split-screen game where you use your cannon to try to
                either hit your opponents airship vitals, or destroy their ship
                enough that they fall off!"
                madeForText="Sheffield Uni Jam 2023 (ShefJam 9)"
                timeText="42 Hours"
                resultText="Won People's Choice Award"
                />

                <GameJamBox name="Mortal Compass" link="https://sammot.itch.io/mortalcompass"
                image={MortalCompassImg} altText="'Mortal compass' screenshot"
                theme={'"Delay the Inevitable"'} 
                description="You are a Reaper with a compass that points to mortals in the living realm.
                You must locate them using your compass, then switch out of the dead realm
                and harvest their soul to grant you some more time to live. But you must be careful,
                as the ones you reap come back to haunt you in the dead realm."
                madeForText="Ludum Dare 50"
                timeText="72 Hours"
                resultText="Placed 718th / ~2200"
                />
            </div>

            <div className="section-end-padding"></div>
        </div>
    )
}
export default GameJams;