import React from 'react';
import './languages.css';

function LangBox( {langName, image, imageAlt, progress, text, isVisible}){

    const progressBarStyle = {
        width: isVisible ? progress : '0%',
    };

    return(
        <div className="lang-box">
            <div className="lang-box-thumbail">
                <img src={image} alt={imageAlt}></img>

                <div className="lang-progress">
                    <p><b>{langName}</b></p>
                    <div className="progress-bar-bg">
                        <div className="progress-bar" id="cs-progress" style={progressBarStyle}></div>
                    </div>
                </div>
            </div>

            <div className="lang-extra">
                <p>{text}</p>
            </div>
        </div>
    )
}

export default LangBox;