import React from 'react';
import './section.css';

function Contact(){
    return(
        <div>
            <div class="contact-flex">
                <div><p><b>Email: </b></p></div>
                <div><p><span class="blockspam" aria-hidden="true">PLEASE GO AWAY!</span> j{/*iyf9yguovgouvihp*/}aku{/*scvh-aspncip*/}bb{/*cvasbvolas*/}ala{/*j[onasda*/}busin{/*sasfasfasedjcs*/}ess{/*sasdagehqfs*/}@{/*asiufqwh0fuob;akn*/}gma{/*asfafiphaobc*/}il.co{/*asigf9qgs*/}m</p></div>
            </div>

            <div class="section-end-padding"></div>
        </div>
    )
}

export default Contact;