import React, { useEffect, useState, useRef } from 'react';
import './section.css';

function Section ({ title, sectionId, showThreshold, isSubsection, children }) {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
      const sectionRefCurrent = sectionRef.current;
      const textAreaRefCurrent = textAreaRef.current;

      const options = {
        threshold: showThreshold || 0.3
      }
    
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= options.threshold) {
            setIsVisible(true);
          }
        });
      }, {
        threshold: options.threshold
      });
    
      const hideObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setIsVisible(false);
          }
        });
      }, {
        threshold: 0
      });
    
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
        hideObserver.observe(sectionRef.current);
      }
  
      if (textAreaRef.current) {
        observer.observe(textAreaRef.current);
        hideObserver.observe(textAreaRef.current);
      }
  
      return () => {
        if (sectionRefCurrent) {
          observer.unobserve(sectionRefCurrent);
          hideObserver.unobserve(sectionRefCurrent);
        }
        if (textAreaRefCurrent) {
          observer.unobserve(textAreaRefCurrent);
          hideObserver.unobserve(textAreaRefCurrent);
        }
      };
    }, [showThreshold]);

    return(
        <div>
            {isSubsection === true ? <h3>{title}</h3>: <h2>{title}</h2>}
            <section ref={sectionRef} className={`section-content ${isVisible ? 'show' : 'hidden'}`} id={sectionId ? sectionId : undefined}>
                <div ref={textAreaRef} className={`section-text-area ${isVisible ? 'show' : 'hidden'}`}>
                {React.Children.map(children, (child) => {
                  if (child.type !== Section) {
                    return React.cloneElement(child, { isVisible });
                  }
                  return child;
                })}
                </div>
            </section>
        </div>
    )
}

export default Section