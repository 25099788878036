import React from 'react';
import './header.css';

function HeaderButton ({url, image, imageSize, text, textId, boldText}) {
    return (
        <div className="header-button">
            <a href={url}>
                <div className="header-button-content">
                    {image && (
                    <svg className="header-button-image" viewBox={`0 0 ${imageSize} ${imageSize}`} role="img" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d={image}
                        />
                    </svg>
                    )}
                    <p className="header-button-text" id={textId ? textId : undefined}>
                        {boldText ? <b>{text}</b> : text}
                    </p>
                </div>
            </a>
        </div>
    )
}

export default HeaderButton