import React, {useState, useEffect, useRef} from 'react';

import './stackedslider.css';

function StackedSlider({images}){
  
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    const handleClick = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const offsetAdjust = {
        transform: `translateX(-${(images.length+1) * 5}px)`
    }

    useEffect(() => {
        const imageWrappers = sliderRef.current.querySelectorAll('.slider-image-wrapper');
        imageWrappers.forEach((wrapper, index) => {
          const image = wrapper.querySelector('img');
          const offset = index * 5; // Adjust the offset value as needed
          image.style.left = `${offset}%`;
          image.style.top = `${offset}%`;
        });
    }, []);
  
    return (
        <div className="stacked-slider" ref={sliderRef} onClick={() => handleClick()} style={offsetAdjust}>
            {images.map((image, index) => (
                <div className="slider-image-wrapper" key={index}>
                <div className="aspect-ratio-box">
                    <img
                    src={image}
                    alt={`Slide ${index}`}
                    className={index === currentIndex ? 'slider-image active' : 'slider-image'}
                    />
                </div>
                </div>
            ))}
        </div>
    );
  };
  
  export default StackedSlider;