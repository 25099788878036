import React from "react";
import './section.css';

function AboutMe (){
    return(
        <div>
            <p><b>Hello, I'm Jakub,</b> a 19-year-old currently residing in the UK, though I was originally born in Poland. 
                Currently, I'm pursuing a degree in Computer Science at the University of Sheffield, in which I am in my second year of. 
                I have recently secured a placement at Lockheed Martin as a Software Engineer, which I will be starting next academic year.</p>
            <p>I have been passionate about Computer Science since I was first introduced to coding through python in Year 9,
                and since then I'm always working on some project be it big or small. 
            </p>
            <div className="section-end-padding"></div>
        </div>
    )
}

export default AboutMe