import React from 'react';
import Section from '../Section.jsx'
import Springman from './Springman.jsx';
import './gamedev.css';
import GameJams from './GameJams.jsx';
import Mentoring from './Mentoring.jsx';

function GameDev(){
    return(
        <div>
            <p>Although I have been interested in Game Development since making games on Scratch in primary school, I got really into Unity
                after the end of Year 11, when I had started to make my first big game project.
            </p>
            <p>I decided I wanted to make a casual mobile game, fit with all the industry-standard services like in-game purchases etc, however
                I hugely under-estimated how long this would take me while doing it on the side of A-Levels and University, which was my primary focus.
            </p>
            <p>
                <b>However, </b> in 2021, I got nominated as a Finalist for 
                the <span class="highlight"><b>BAFTA Young Game Developer Award</b></span> for an early version of my game!
            </p>

            <div class="video-embed-container">
                <iframe title="BAFTA video" class="video-embed" src="https://www.youtube.com/embed/oBpEQhd3A1Q?start=1237" frameborder="0" allowfullscreen></iframe>
            </div>
            <div class="section-end-padding"></div>

            <Section title="Springman" isSubsection={true}>
                <Springman/>
            </Section>
            <div class="section-end-padding"></div>

            <Section title="Game Jams" isSubsection={true}>
                <GameJams/>
            </Section>
            <div class="section-end-padding"></div>

            <Section title="Mentoring" isSubsection={true}>
                <Mentoring/>
            </Section>
        </div>
    )
}

export default GameDev;