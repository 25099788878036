import React from 'react';

function GameJamBox({name, link, image, altText, theme, description, madeForText, timeText, resultText}){
    return(
        <div class="game-jam-box">
            <a href={link}>
                <div className="game-jam-box-thumbnail">
                    <p><b>{name}</b></p>
                    <img src={image} alt={altText}></img>
                </div>
                <div className="jam-details">
                    <p><b>Theme: <span className="highlight">{theme}</span></b></p>
                    <p>{description}
                    </p>
                    <p>Made for {madeForText}</p>
                    <p><b>Made in: </b>{timeText}</p>
                    <p><b>{resultText}</b></p>
                </div>
            </a>
        </div>
    )
}
export default GameJamBox;