import React from 'react';
import mentoringImage from '../../../images/mentoring/stateslecture.jpg';
import './gamedev.css';

function Mentoring(){
    return(
        <div>
            <p>I'm also a mentor for <a href="https://sites.google.com/sheffield.ac.uk/projectpixel/meet-the-team?authuser=0">Project Pixel</a>, 
            which is a student-led game development endeavour for charity at the University of Sheffield. Since I have experience in game 
            design/development and using the Unity engine, I get called in to help out with various aspects of their ongoing projects.</p>

            <div className="mentoring-container">
                <div className="mentoring-item side-text">
                <p>
                    I even once prepared and gave a lecture on <b>State Based Design</b>, consisting of first explaining the problem that 
                    having a single controller script brings, then showcasing how seperating behaviours into States can be beneficial to 
                    code robustness, scalability, and readability. 
                </p>
                <p>
                    I also demonstrated how you can implement a Finite State Machine in C#/Unity, and also covered theory on more complex machines.
                </p>
                <p>
                    Here is the link to the <a href="https://docs.google.com/presentation/d/1X6NobnEvOlSpbg406Kg_UFvt3Nc1ZYaOqEHi8M53QBc/edit#slide=id.p">presentation slides</a>
                </p>
                </div>
                <div className="mentoring-item image-holder">
                    <img src={mentoringImage} alt="Me in lecture theatre"></img>
                </div>
            </div>

            <div className="section-end-padding"></div>
        </div>
    )
}

export default Mentoring;