import React from 'react';
import LangBox from './LangBox';
import './languages.css';

import csharpimg from '../../../images/langs/csharp.png'
import cppimg from '../../../images/langs/cpplogo.png'
import javaimg from '../../../images/langs/java.png'
import jsimg from '../../../images/langs/JavaScript-logo.png'
import pythonimg from '../../../images/langs/python.png'
import rubyimg from '../../../images/langs/rubypng.png'
import haskellimg from '../../../images/langs/haskell.png'
import reactimg from '../../../images/langs/react.png'

function Languages( { isVisible }){
    return(
    <div>
        <p>The following are languages I have used and my experience* with them. Hover over one (or click on mobile) to learn more about what I have done 
        with each language.
        </p>

        <div id="languages-container">

        <LangBox
        langName="C#" image={csharpimg} imageAlt="C# Logo" progress="75%" isVisible={isVisible}
        text="I've been using C# for game development in Unity for about 4 years now, however me and my friends have begun using C# to make our
        own MonoGame based game engine"/>

        <LangBox
        langName="JavaScript" image={jsimg} imageAlt="JS Logo" progress="60%" isVisible={isVisible}
        text="This was the language we used in A-Levels, particularly in tandem with p5.js. For my Year 2 project, I made an
        RTS style game using JS with p5."/>

        <LangBox
        langName="Java" image={javaimg} imageAlt="Java Logo" progress="70%" isVisible={isVisible}
        text="I have been learning Java through my University modules. My most successful assignment so far was my Software team project, shown in the 
        next section"/>

        <LangBox
        langName="Ruby" image={rubyimg} imageAlt="Ruby Logo" progress="45%" isVisible={isVisible}
        text="In Year 1 of University, we had a team assignment to build a course finder website, think Netflix + UCAS. We were taught
        how to use Ruby with Sinatra for the backend of this."/>

        <LangBox
        langName="C++" image={cppimg} imageAlt="C++ Logo" progress="15%" isVisible={isVisible}
        text="This summer I started up on LeetCode, and since I have also been meaning to learn C++, I have been solving LeetCode problems
        using it, as a way to drill in the language basics."/>

        <LangBox
        langName="Python" image={pythonimg} imageAlt="Python Logo" progress="35%" isVisible={isVisible}
        text="I used Python in my Data Driven Computing module in my second year, particularly to make an AI that would classify
         chess pieces based off of passed in images of a random piece."/>

        <LangBox
        langName="Haskell" image={haskellimg} imageAlt="Haskell Logo" progress="40%" isVisible={isVisible}
        text="I learned Haskell in semester 1 of my second year, and completed an assignment where I had to make 
        a player that would intelligently play Dominoes"/>

        <LangBox
        langName="React JS" image={reactimg} imageAlt="Haskell Logo" progress="15%" isVisible={isVisible}
        text="This website was made with React! I have been learning react in order to help me make web apps."/>
        </div>
        
        <p className="aside-text">*the progress bars are completely arbitrary, they are rather representative of my confidence
                             in using the language due to my experience with it</p>

        <div className="section-end-padding"></div>
    </div>
    )
}

export default Languages