import React from 'react';
import StackedSlider from './StackedSlider';
import './software.css';

import tos1 from '../../../images/trainssheffield/tos1.png';
import tos2 from '../../../images/trainssheffield/tos2.png';
import tos3 from '../../../images/trainssheffield/tos3.png';
import tos4 from '../../../images/trainssheffield/tos4.png';

function Software(){
    return(
        <div>
            <p>
            In my first semester of Year 2, we were put into teams of 4, made up of pairs of chosen partners. Our task was to read a requirements document 
            from a client, then make a design of the software consisting of an <b>Information Diagram</b>, <b>Database Profile</b> and <b>State Machine Diagram</b> (all in UML). We 
            also made a <b>Use Case Diagram</b>, as we thought it would be helpful in checking our progress and delegating tasks, which it was.
            </p>
            <div className="container">
                <div className="item side-text">
                    <p>
                    The software we had to make was an <b>in-store digital kiosk system</b>, where customers could come in, log in or register, then make orders and send them 
                    to the staff to fulfil.
                    The staff had their own interface where they can see incoming orders, they can create new products, update product records,
                    check-in new stock, etc. Think a system similar to how <b>Argos</b> or <b>Screwfix</b> works in-store. 
                    </p>
                    <p>Here are some screenshots of the finished product. Click on the images to flick through them.
                    </p>
                </div>
                <div className="item slider-holder">
                    <StackedSlider images={[tos1, tos2, tos3, tos4]}/>
                </div>
            </div>
        </div>
    )
}

export default Software;