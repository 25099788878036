import React from 'react';
import './section.css'
import goToIcon from '../../images/icons/gotopage.png';

function Youtube(){
    return(
        <div>
            <p>I run a small YouTube channel dedicated to game development related videos aimed towards beginners. It's named after
                mine and my friends partnership, Contraband Software.
            </p>

            <p><b>Latest Upload:</b></p>
            <div class="video-embed-container">
                <iframe title="latest youtube upload" class="video-embed" src="https://www.youtube.com/embed/mNCrF6zckgM" frameborder="0" allowfullscreen></iframe>
            </div>

            <div class="button-link-container">
                <a href="https://www.youtube.com/channel/UCcVLAn4udaHe5bddtfvr8vw">
                    <p><b>My YouTube Channel</b></p>
                    <img src={goToIcon} alt="go-to-page icon"></img>
                </a>
            </div>
            <div class="section-end-padding"></div>
        </div>
    )
}

export default Youtube;