import React from 'react';
import googlePlayImage from '../../../images/springman/googleplay.png';
import ss1 from '../../../images/springman/ss1.jpg';
import ss2 from '../../../images/springman/ss2.jpg';
import ss3 from '../../../images/springman/ss3.jpg';
import ss4 from '../../../images/springman/ss4.jpg';
import ss5 from '../../../images/springman/ss5.jpg';

function Springman({isVisible}){

    const centerStyle = {
        textAlign: 'center'
    };

    return(
        <div>
            <p style={centerStyle}>The game is complete and is available for download on Google Play!</p>
            <p className="aside-text" style={centerStyle}>Sorry iPhone users</p>

            <div id="springman-screenshots" className={isVisible ? 'show' : 'hidden'}>
                <img src={ss1} alt="screenshot of springman 1"></img>
                <img src={ss2} alt="screenshot of springman 2"></img>
                <img src={ss3} alt="screenshot of springman 3"></img>
                <img src={ss4} alt="screenshot of springman 4"></img>
                <img src={ss5} alt="screenshot of springman 5"></img>
            </div>

            <div id="springman-links">
                <a href="https://play.google.com/store/apps/details?id=software.contraband.springman&pcampaignid=web_share">
                    <img id="springman-googleplay" src={googlePlayImage} alt="google play page button"></img>
                </a>
            </div>

        </div>
    )
}

export default Springman;