import React from 'react';
import './section.css'
import goToIcon from '../../images/icons/gotopage.png';

function Tutoring(){
    return(
        <div>
            <p>I have been doing general tutoring since A-Levels where I would lead small one-to-one sessions for free for friends. 
                However, I also won an award from the Math Department of my Sixth Form for being the best math peer mentor. 
            </p>
            <p>Now I host online private tutoring for <b>A-Level Maths</b> and <b>Computer Science</b>, 
                and you can view my tutoring page by clicking below 
            </p>
            <div class="button-link-container">
                <a href="https://www.superprof.co.uk/hands-maths-and-computer-science-tutoring-computer-science-student.html">
                    <p><b>My Superprof Page</b></p>
                    <img src={goToIcon} alt="go-to-page icon"></img>
                </a>
            </div>
        
            <div class="section-end-padding"></div>
        </div>
    )
}

export default Tutoring;