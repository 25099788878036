import React from 'react';
import './myeducation.css';
import SubjectResult from './SubjectResult';

function MyEducation (){
    return (
        <div>
            <p><b>In my GCSE's</b> I achieved 11, 7-9 Grades along with an A in Polish</p>
            <p><b>For A-Levels,</b> I got the following results:</p>
            <div className="results-div">
                <ul>
                    <SubjectResult subjectname="Mathematics" grade="A*"/>
                    <SubjectResult subjectname="Electronics" grade="A*"/>
                </ul>

                <ul>
                    <SubjectResult subjectname="Computer Science" grade="A*"/>
                    <SubjectResult subjectname="AI EPQ (Artefact)" grade="A*"/>
                </ul>

                <ul>
                    <SubjectResult subjectname="Accounting" grade="A"/>
                </ul>
            </div>
            <p><b>In my First Year of University,</b> I got the following results:</p>

            <div className="results-div">
                <ul>
                    <SubjectResult subjectname="Algorithms" grade="88%"/>
                    <SubjectResult subjectname="Java Programming" grade="84%"/>
                    <SubjectResult subjectname="Web Tech" grade="84%"/>
                </ul>

                <ul>
                    <SubjectResult subjectname="Foundations of CS" grade="80%"/>
                    <SubjectResult subjectname="Software Engineering" grade="76%"/>
                </ul>

                <ul>
                    <SubjectResult subjectname="Machines & Intelligence" grade="76%"/>
                    <SubjectResult subjectname="Devices & Networks" grade="70%"/>
                </ul>
            </div>

            <div className="section-end-padding"></div>
        </div>
    )
}

export default MyEducation
